import React, { useEffect } from 'react';
import './Product2.css'; // Assuming you're using the same CSS file

const Product8 = () => {     

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="product-container">
      <h1 className="product-title">Boylesdata Digital Monitor For Anaesthesia</h1>
      <img 
        className="product-image1"
        src='./img/products/Boyles Data.png'
        alt="Boylesdata Digital Monitor For Anaesthesia Image -1" 
      />
      <h3 className="product-sub-title">
        An IoT based Tablet Operated Wireless Surgeon’s OT Control Panel for Monitoring + Control + Data storage + Data Analysis + Report Generation
      </h3>
      <p className="product-description" style={{ fontWeight: "bold" }}>Applications:</p>
      <ul className="product-description">
      <li>- Upgrading Basic Boyles Anesthesia Trolley</li>
      <li>- Digitalizing the Input Gas and output Gas parameters</li>
      <li>- FiO2 along with Gas pressure Alarm</li>
      </ul>

      <p className="product-description" style={{ fontWeight: "bold" }}>Input & Output Gas Quality:</p>
      <ul className="product-description">
      <li>- All three input Gas – O2, N2O, Air pressure monitoring</li>
      <li>- Output Gas – Oxygen %, Pressure, Flow rate, Temperature Monitoring</li>
      </ul>

      <p className="product-description" style={{ fontWeight: "bold" }}>Placement:</p>
      <ul className="product-description">
    <li>- Can be used as Trolley mounted unit or Table-top unit</li>
    </ul>

      <a href='./catalog/BoylesData Anesthesia Monitor catalog.pdf' download className="product-button">Download Catalog</a>
      
      <div className="product-features">
        <h2>Product Features</h2>
        <ul className="product-description">
          <li>Compact, Light Weight, Sleek unit</li>
          <li>Anesthesia Trolley mountable as well as Table-top unit</li>
          <li>Standard input gas connections</li>
          <li>Built-in Rechargeable battery, charger, and switch-over circuit</li>
          <li>Big Touch screen color LCD display control panel</li>
          <li>High & Low User Adjustable Alarms for each parameter – Audio and Video</li>
          <li>Real Date-Time display with Room Temperature and Humidity measurement</li>
          <li>Wi-Fi Enabled Control panel for Mobile connectivity for wireless Monitoring + Alarm + Control + Data storage + Data Analysis + Report Generation</li>
        </ul>
      </div>

      <div className="product-specifications">
        <h2>Specifications</h2>
        <ul className="product-description">
          <li>Input Gas Pressure Monitoring – Three separate channels:</li>
          <ul>
            Oxygen, N2O, Air: 0-100 PSI with 1% accuracy, 1 PSI resolution
          </ul>
          <li>Output Gas Quality Monitoring:</li>
          <ul>
            Oxygen Concentration: 0-100% oxygen purity with 1% resolution 
            Gas Pressure: 0-15 PSI with 1% accuracy, 0.1 PSI resolution 
            Gas Flow Rate: 0-10 LPM with 1 LPM accuracy, 0.1 LPM resolution 
          </ul>
          <li>Room Temperature: 0-50°C with 1°C resolution</li>
          <li>Room Humidity: 0-100% with 1% resolution</li>
          <li>Battery backup time after full charge: 4-6 hours</li>
          <li>Wide Input Voltage range: 90V to 260V to cover mains voltage</li>
        </ul>
      </div>

      <p className="training-info">
        Training will be provided at Vadodara office for your installation engineers. Installation manual with easy-to-understand procedures will be provided.
        For any customization/additional feature/change/modification – wherever possible, mutual decision will be taken.
      </p>

      <div id="footer">
        <div className="footer-line"></div> 
        <div className="container text-center">
          <h4 style={{ color: "black", fontWeight: "bold" }}>
            <p>&copy; 2024 Wave Visions. All Rights Reserved.</p>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Product8;
