import React from 'react';

// Inline styles for design and animations
const styles = {
  container: {
    textAlign: 'center',
    padding: '40px',
    margin:"90px",
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#e0f7fa',
    minHeight: '100vh',
  },
  header: {
    color: '#00796b',
    fontSize: '38px',
    marginBottom: '20px',
    letterSpacing: '2px',
    textTransform: 'uppercase',
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
    animation: 'fadeIn 1s ease-in-out',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '30px',
    marginBottom: '30px',
  },
  image: {
    width: '320px',
    height: 'auto',
    boxShadow: '0 6px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '15px',
    transform: 'scale(1)',
    transition: 'transform 0.3s ease-in-out',
  },
  imageHover: {
    transform: 'scale(1.1)', // Enlarge on hover
  },
  buttonContainer: {
    marginBottom: '50px',
  },
  button: {
    padding: '15px 30px',
    backgroundColor: '#ff4081',
    color: 'white',
    fontSize: '20px',
    border: 'none',
    borderRadius: '50px',
    cursor: 'pointer',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease-in-out, background-color 0.3s ease',
  },
  buttonHover: {
    transform: 'scale(1.05)', // Slight enlarge on hover
    backgroundColor: '#f50057',
  },
  footer: {
    marginTop: '0px',
    color: 'black',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#f1f1f1',  // Add a background color for the footer
    padding: '10px', // Add padding for better spacing
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
};

class Product5 extends React.Component {
  state = {
    isHoveringImage1: false, // Track hover state of image 1
    isHoveringImage2: false, // Track hover state of image 2
    isHoveringButton: false,
  };

  // Button hover functions
  handleButtonMouseEnter = () => {
    this.setState({ isHoveringButton: true });
  };

  handleButtonMouseLeave = () => {
    this.setState({ isHoveringButton: false });
  };

  // Image 1 hover functions
  handleImage1MouseEnter = () => {
    this.setState({ isHoveringImage1: true });
  };

  handleImage1MouseLeave = () => {
    this.setState({ isHoveringImage1: false });
  };

  // Image 2 hover functions
  handleImage2MouseEnter = () => {
    this.setState({ isHoveringImage2: true });
  };

  handleImage2MouseLeave = () => {
    this.setState({ isHoveringImage2: false });
  };

  // Open the link in a new tab
  handleButtonClick = () => {
    window.open('https://www.oxydata.in/', '_blank');
  };

  render() {
    const { isHoveringImage1, isHoveringImage2, isHoveringButton } = this.state;

    return (
      <div style={styles.container}>
        <h1 style={styles.header}>Medical Oxygen Analyzer OxyData</h1>

        {/* Image Container with individual hover effect */}
        <div style={styles.imageContainer}>
          <img
            src="./img/products/OxyData-P-500x500-C.png"
            alt="OxyData Product Front View"
            style={
              isHoveringImage1 ? { ...styles.image, ...styles.imageHover } : styles.image
            }
            onMouseEnter={this.handleImage1MouseEnter}
            onMouseLeave={this.handleImage1MouseLeave}
          />
          <img
            src="./img/products/OxyData-C-500x500-C.png"
            alt="OxyData Product Side View"
            style={
              isHoveringImage2 ? { ...styles.image, ...styles.imageHover } : styles.image
            }
            onMouseEnter={this.handleImage2MouseEnter}
            onMouseLeave={this.handleImage2MouseLeave}
          />
        </div>

        {/* Button with hover and animation */}
        <div style={styles.buttonContainer}>
          <button
            style={
              isHoveringButton ? { ...styles.button, ...styles.buttonHover } : styles.button
            }
            onClick={this.handleButtonClick}
            onMouseEnter={this.handleButtonMouseEnter}
            onMouseLeave={this.handleButtonMouseLeave}
          >
            Visit OxyData Official Website
          </button>
        </div>

        {/* Footer Section */}
        <div  style={styles.footer}>
          <p>&copy; 2024 Wave Visions. All Rights Reserved.</p>
        </div>
      </div>
    );
  }
}

export default Product5;
